import { h , Fragment, Component } from 'preact';
import './style';
import Header from './header';
import Router from 'preact-router';

import Homepage from './homepage';
import Portfolio from './portfolio';
import Resume from './resume';
import Contact from './contact';

import { createBrowserHistory } from 'history';

const App = () => {

	const history = createBrowserHistory();
	const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
	if (path) {
		history.replace(path);
	}

	return (
		<div class='wrapper'>
			<Header />
			<Router>
				<Homepage path="/" />
				<Portfolio path="/portfolio" />
				<Resume path="/resume" />
				<Contact path="/contact" />
			</Router>
			<div class="footer">
			</div>
		</div>
	)
}

export default App;
