import { h , Fragment, Component } from 'preact';

const Contact = () => {
	return(
		<div class="content-main">
			<div class="intro-box">
				<h1>Contact</h1>
				<ul>
					<li><span style="font-weight: strong;">email </span>connor@s1nus.com</li>	
					<li><a href="https://www.linkedin.com/in/connor-o-hara-2a8a7696/">LinkedIn</a></li>
					<li><a href="https://www.github.com/s1nus">GitHub</a></li>
				</ul>
			</div>
		</div>
	);
}
export default Contact;
