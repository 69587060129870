import { h , Fragment, Component } from 'preact';

const Header = () => {
	return (
		<div class="header">
			<a href="/"><h1>S1nus.com</h1></a>
			<div class="my-links">
				<a href="/portfolio">Stuff I Like</a>
				<a href="/resume">Resume</a>
				<a href="/contact">Contact</a>
			</div>
		</div>
	);
}
export default Header;
