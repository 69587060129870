import { h , Fragment, Component } from 'preact';

const Resume = () => {
	return(
		<div class="content-main">
		{/*<div class="intro-box">
				<h1>Resume</h1>
			</div>*/}
			<div class="intro-box resume">
				<iframe src="Resume.pdf" />
			</div>
		</div>
	);
}
export default Resume;
