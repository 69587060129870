import { h , Fragment, Component } from 'preact';

const HomePage = () => {
	return(
		<div class="content-main">
			<div class="intro-box">
				<h1>Hey what's up</h1>
				<p>My name is Connor O'Hara.</p>
				<p>I live in New York City and I'm from Boston</p>
				<p>I like operating systems, software development, and playing around with all kinds of technology.</p>
				<a href="https://github.com/s1nus"><img src="github.png" width="100" /></a>
			</div>
		</div>
	);
}
export default HomePage;
