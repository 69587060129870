import { h , Fragment, Component } from 'preact';

const Portfolio = () => {
	return(
		<div class="content-main">
			<div class="intro-box">
				<h1>Favorite Programming Languages</h1>
				<ul>
					<li>Rust</li>
					<li>C</li>
					<li>JavaScript</li>
					<li>Python</li>
					<li>Go</li>
				</ul>
			</div>
			<div class="intro-box">
				<h1>Tech I've Studied and Worked With</h1>
				<ul>
					<li>Applied Cryptography</li>
					<li>"Serverless" Backends</li>
					<li>Frontend development and design with React and Preact using hooks</li>
					<li>Complex e-commerce solutions</li>
					<li>High-performance concurrent programming for Finance</li>
				</ul>
			</div>
		</div>
	);
}
export default Portfolio;
